<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Pencarian Daftar Kegiatan Proyek
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tag Admin</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilter.tag_admin_id"
            :options="tagAdminOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tag Publik</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilter.tag_publik_id"
            :options="tagPublikOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Proyek</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilter.proyek_id"
            :options="proyekOptions"
            class="w-100"
            :reduce="val => val.value"
          />
          <!-- @input="(val) => $emit('update:proyekFilter', val)" -->
        </b-col>
        <b-col
          cols="12 mt-2"
        >
          <b-button
            variant="primary"
            @click="cariData()"
            class="mr-1"
            :key="btnKey"
          >
            <span class="text-nowrap">Cari</span>
          </b-button>
          <b-button
            variant="success"
            @click="resetForm()"
            class="mr-1"
          >
            <span class="text-nowrap">Reset</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    tagFilter: {},
    tagFilterPublik: {},
    proyekFilter: {},
    tagAdminOptions: {
      type: Array,
    },
    tagPublikOptions: {
      type: Array,
    },
    proyekOptions: {
      type: Array,
    },
  },
  data() {
    return {
      btnKey: 0,
      searchFilter: {
        proyek_id: null,
        tag_publik_id: null,
        tag_admin_id: null,
      },
    }
  },
  methods: {
    resetForm() {
      this.searchFilter = {
        proyek_id: null,
        tag_publik_id: null,
        tag_admin_id: null,
      }
    },
    cariData() {
      // console.log(this.searchFilter)
      this.btnKey += 1
      this.$emit('update:newFilter', this.searchFilter)
      this.$emit('update:btnKey', this.btnKey)
    },
    changeUPT(value) {
      // this.$emit('emitReceiverUPT', value)
      // this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
